/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Calistoga&family=DM+Sans:wght@400;500;700&display=swap');


// fonts
$base-font-size: 15px;
$base-font: 'Montserrat', sans-serif;
$heading-font: 'Montserrat' , sans-serif;



// color
$dark-gray: #1E144F;
$body-color: #687693;
$white: #fff;
$light: #D6D0CD;
$black: #000;
$green: #00A51A;
$small-black: #5F5976;
$cyan: #DADADA;

$theme-primary-color: #72569D; 
$theme-primary-color-s2: #FEF5F0; 
$theme-primary-color-s3: #F1B290;
$body-bg-color: #fff;
$section-bg-color:#FAE2D4;
$section-bg-color2:#f5f5f5;
$text-color: #1E144F;
$text-color-s2: #777094; 
$text-light-color: #736A98;
$heading-color: $dark-gray;
$border-color: #FDEAE0;
$border-color-s2: #F2D1BF;
$border-color-s3: #584F88;
$border-color-s4: #F5EEEA;
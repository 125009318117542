/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer,
.wpo-site-footer-s2 {
    background: $theme-primary-color-s2;
    position: relative;
    font-size: 15px;
    overflow: hidden;
    z-index: 1;
    padding-top: 50px;
    margin-top: -193px;

    @media (max-width:991px) {
        padding-top: 0;
        margin-top: 0;
    }

    z-index: 1;

    .f-shape-1 {
        position: absolute;
        top: -100px;
        left: -50px;
        z-index: -1;


        svg {
            circle {
                fill: #F3C4AA;
            }
        }
    }

    .f-shape-2 {
        position: absolute;
        right: 0;
        bottom: -100px;
        z-index: -1;

        svg {
            circle {
                fill: #F3C4AA;
            }
        }
    }

    &::before {
        position: absolute;
        left: 0;
        bottom: -70px;
        content: url(../../images/f-shape-1.png);
        z-index: -1;


        @media(max-width:767px) {
            display: none;
        }

    }

    &::after {
        position: absolute;
        right: 0;
        bottom: -7px;
        content: url(../../images/f-shape-2.png);
        z-index: -1;

        @media(max-width:767px) {
            display: none;
        }
    }

    ul {
        list-style: none;
    }

    p {
        color: $text-color-s2;
    }

    li {
        color: $white;
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer {
        padding: 80px 0;
    }

    @media (max-width: 991px) {

        .wpo-upper-footer {
            padding: 50px 0 0;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer {
            padding: 60px 0 0;
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer .col {
            min-height: 235px;
            margin-bottom: 70px;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer .col {
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title {
        margin-bottom: 20px;
    }

    @media (max-width: 767px) {

        .widget-title {
            margin-bottom: 20px;
        }
    }


    .widget-title {
        h3 {
            font-size: 24px;
            color: $text-color;
            margin: 0;
            position: relative;
            font-family: $heading-font;
            font-weight: 400;
        }
    }

    .about-widget {
        .logo {
            max-width: 180px;
        }
    }

    .about-widget {
        p {
            margin-bottom: 0.8em;
            line-height: 1.9em;
            color: $text-color-s2;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .link-widget,
        .social-widget {

            ul {
                li {
                    display: inline-block;
                    padding-top: 0;
                    padding-right: 15px;

                    a {
                        border: 1px solid #d1d1d1;
                        border-radius: 50px;
                        width: 35px;
                        height: 35px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            border: 1px solid $theme-primary-color;
                        }

                    }
                }
            }
        }
    }

    .wpo-contact-widget {
        .contact-ft {
            margin-top: 20px;
            color: $text-color-s2;
            font-size: 16px;

            @media(min-width:991px) {
                padding-right: 30px;
            }

            ul {
                li {
                    padding-bottom: 15px;
                    position: relative;
                    padding-left: 35px;
                    color: $text-color-s2;
                    font-size: 16px;

                    i {
                        position: absolute;
                        left: 0;
                        top: -3px;
                    }

                    .fi:before {
                        font-size: 20px;
                        margin-right: 15px;
                    }
                }
            }
        }

    }

    .social-widget {
        margin-top: 20px;
    }

    .link-widget,
    .social-widget {
        overflow: hidden;

        @media (max-width: 1199px) {}

        @media (max-width: 991px) {
            padding-left: 0;
        }

        @media (max-width: 767px) {
            max-width: 350px;
        }

        ul {
            li {
                position: relative;

                a {
                    color: $text-color-s2;
                    font-size: 15px;

                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }

            li+li {
                padding-top: 15px;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        .link-widget {
            padding-left: 75px;
        }
    }

    .tag-widget {
        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                a {
                    display: block;
                    padding: 8px 20px;
                    background: rgba(255, 255, 255, .1);
                    font-size: 16px;
                    color: $white;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    border-radius: 5px;
                }
            }
        }
    }

    .social-widgets {
        ul {
            li {
                a {
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 600;
                    color: $white;
                    margin-bottom: 10px;

                    &:hover {
                        color: $theme-primary-color;
                    }

                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .newsletter-widget {
        form {
            margin-top: 25px;
            position: relative;

            input {
                background-color: $white;
                height: 50px;
                color: $text-color;
                padding: 6px 20px;
                border-radius: 5px;
                border: none;
                box-shadow: none;
            }

            .submit {
                position: absolute;
                right: 20px;
                top: 55%;
                @include translatingY();

                button {
                    background: transparent;
                    border: 0;
                    outline: 0;
                    font-size: 20px;
                    color: $theme-primary-color;
                    margin-top: -2px;
                    position: relative;
                    right: -5px;
                }
            }
        }
    }


    .wpo-lower-footer {
        text-align: center;
        position: relative;

        .row {
            padding: 20px 0;
            position: relative;
            border-top: 1px solid #ffe0cf;
        }

        .copyright {
            font-size: 15px;
            font-size: 0.9375rem;
            margin: 0;
            font-size: 17px;
            font-weight: 500;
            line-height: 22px;

            span {
                color: $theme-primary-color;
                padding: 0 10px 0 5px;
            }

            .copyright-icon {
                padding-right: 10px;
                color: $theme-primary-color;
            }

            a {
                color: $white;
                text-decoration: none;
            }

            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }

        .link {
            margin-bottom: 0;
            display: flex;
            justify-content: right;

            a {
                color: $white;
            }

            span {
                color: $theme-primary-color;
                padding: 0 10px;
            }

            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }
    }

    .instagram {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0px -3px;

            li {
                -ms-flex: 0 0 33.33%;
                flex: 0 0 33.33%;
                max-width: 33.33%;
                margin-bottom: 5px;
                padding: 0px 3px;

                img {
                    width: 100%;
                }
            }
        }
    }
}

.wpo-site-footer-s2 {
    padding-top: 0;
    margin-top: 0;
    margin-top: 0;
}
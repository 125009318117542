ul.smothscroll {
    position: fixed;
    bottom: 25px;
    right:30px;
    list-style: none;
    z-index: 99;
}
ul.smothscroll .termin-button {
    background-color: rgba(16, 122, 202, 0.95);
    line-height: 45px;
    border-radius: 15px;
    display: block;
    text-align: center;
    color: #fff;
    background-size: 200%,1px;
    background-position: 0;
    border: 2px solid rgba(16, 122, 202, 1);
}

ul.smothscroll .termin-button:hover {
    background-color: rgba(16, 122, 202, 1);
    color: #fff;
}

@media(max-width:767px){
    ul.smothscroll a {
        width: 30px;
        height: 30px;
        line-height: 25px;
    }
}